import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ContactHeader from "components/Contact-header/contact-header";
import DarkTheme from "layouts/Dark";
import CustomContactForm from "components/Contact-form/custom-contact-form";

const config ={
  "templateId":"template_elf_contact_us",
  "formType":"contact",
}

const Contact = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    document.querySelector("body").classList.add("contact-page");

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    return () => {
      document.querySelector("body").classList.remove("contact-page");
    };
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <ContactHeader />
      <div className="main-content">
        <CustomContactForm config={config}/>
        <div className="map" id="ieatmaps">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.832386063788!2d77.5991005!3d12.974532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156720e4b2a1%3A0x9436c88de33da6ef!2sElfonze%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1700217662298!5m2!1sen!2sin"
            loading="lazy"
          ></iframe> */}
          <img src='/img/map.webp' alt="world_map_with_locations_of_our_" className="img-fluid"></img>
        </div>

        <Footer hideBGCOLOR />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Contact Us</title>
      <meta key="description" name="description" 
      content="Connect with us easily! Our Contact Us page provides a direct line to our team. Reach out for inquiries, collaborations, or any assistance. We're here to hear." />
    </>
  )
}

export default Contact;
